// src/pages/PrivacyPolicy.js

import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="text-gray-800 dark:text-white p-6 md:p-12 bg-white dark:bg-custom-gradient">
      {/* Page Title */}
      <h1 className="text-4xl font-bold mb-4 text-center">Privacy Policy for NOVA CRAFT LAB LLC</h1>
      <p className="text-center mb-8">
        <strong>Effective Date:</strong> October 1, 2024
      </p>

      {/* Table of Contents */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Table of Contents</h2>
        <ol className="list-decimal list-inside space-y-2">
          <li>
            <a href="#introduction" className="text-blue-600 dark:text-blue-400 underline">
              Introduction
            </a>
          </li>
          <li>
            <a href="#summary-of-key-points" className="text-blue-600 dark:text-blue-400 underline">
              Summary of Key Points
            </a>
          </li>
          <li>
            <a href="#information-we-collect" className="text-blue-600 dark:text-blue-400 underline">
              Information We Collect
            </a>
          </li>
          <li>
            <a href="#how-we-process-your-information" className="text-blue-600 dark:text-blue-400 underline">
              How We Process Your Information
            </a>
          </li>
          <li>
            <a href="#legal-bases" className="text-blue-600 dark:text-blue-400 underline">
              What Legal Bases Do We Rely On to Process Your Personal Information?
            </a>
          </li>
          <li>
            <a href="#sharing-personal-information" className="text-blue-600 dark:text-blue-400 underline">
              When and With Whom Do We Share Your Personal Information?
            </a>
          </li>
          <li>
            <a href="#third-party-websites" className="text-blue-600 dark:text-blue-400 underline">
              What Is Our Stance on Third-Party Websites?
            </a>
          </li>
          <li>
            <a href="#international-transfers" className="text-blue-600 dark:text-blue-400 underline">
              Is Your Information Transferred Internationally?
            </a>
          </li>
          <li>
            <a href="#data-retention" className="text-blue-600 dark:text-blue-400 underline">
              How Long Do We Keep Your Information?
            </a>
          </li>
          <li>
            <a href="#information-safety" className="text-blue-600 dark:text-blue-400 underline">
              How Do We Keep Your Information Safe?
            </a>
          </li>
          <li>
            <a href="#information-from-minors" className="text-blue-600 dark:text-blue-400 underline">
              Do We Collect Information from Minors?
            </a>
          </li>
          <li>
            <a href="#privacy-rights" className="text-blue-600 dark:text-blue-400 underline">
              What Are Your Privacy Rights?
            </a>
          </li>
          <li>
            <a href="#do-not-track" className="text-blue-600 dark:text-blue-400 underline">
              Controls for Do-Not-Track Features
            </a>
          </li>
          <li>
            <a href="#us-privacy-rights" className="text-blue-600 dark:text-blue-400 underline">
              Do United States Residents Have Specific Privacy Rights?
            </a>
          </li>
          <li>
            <a href="#other-regions-rights" className="text-blue-600 dark:text-blue-400 underline">
              Do Other Regions Have Specific Privacy Rights?
            </a>
          </li>
          <li>
            <a href="#updates-to-notice" className="text-blue-600 dark:text-blue-400 underline">
              Do We Make Updates to This Notice?
            </a>
          </li>
          <li>
            <a href="#contact-us" className="text-blue-600 dark:text-blue-400 underline">
              How Can You Contact Us About This Notice?
            </a>
          </li>
          <li>
            <a href="#review-update-delete" className="text-blue-600 dark:text-blue-400 underline">
              How Can You Review, Update, or Delete the Data We Collect from You?
            </a>
          </li>
        </ol>
      </section>

      {/* 1. Introduction */}
      <section className="mb-12" id="introduction">
        <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
        <p className="mb-4">
          NOVA CRAFT LAB LLC ("we," "us," or "our") values your privacy and is dedicated to safeguarding your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your data when you use our iOS applications or interact with our services.
        </p>
        <p>
          By accessing or using our Services, you agree to this Privacy Policy. If you disagree with any part of this policy, please do not use our Services. We may update this policy from time to time, and your continued use of the Services after changes are made signifies your acceptance of the revised policy.
        </p>
      </section>

      {/* 2. Summary of Key Points */}
      <section className="mb-12" id="summary-of-key-points">
        <h2 className="text-2xl font-semibold mb-4">2. Summary of Key Points</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>We collect personal information that you provide to us and information collected automatically when you use our Services.</li>
          <li>We process your information to operate, improve, and administer our Services, communicate with you, ensure security, and comply with legal obligations.</li>
          <li>We share your personal information only in specific situations and with trusted third parties.</li>
          <li>You have rights regarding your personal information, including accessing, updating, or deleting your data.</li>
          <li>We do not knowingly collect data from or market to individuals under 18 years of age.</li>
        </ul>
      </section>

      {/* 3. Information We Collect */}
      <section className="mb-12" id="information-we-collect">
        <h2 className="text-2xl font-semibold mb-4">3. Information We Collect</h2>
        <h3 className="text-xl font-semibold mb-2">Personal Information You Provide</h3>
        <p className="mb-4">
          We collect personal information that you voluntarily provide when using our Services. This may include:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Contact information (name, email address, phone number)</li>
          <li>Account credentials (username, password)</li>
          <li>Payment information (processed securely by third-party payment processors)</li>
          <li>User-generated content (feedback, reviews, messages)</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2 mt-6">Information Collected Automatically</h3>
        <p className="mb-4">
          When you use our Services, we automatically collect certain information, including:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Device information (IP address, device type, operating system)</li>
          <li>Usage data (pages viewed, features used, time spent on the app)</li>
          <li>Location data (with your consent)</li>
          <li>Log data (error reports, diagnostic information)</li>
        </ul>

        <h3 className="text-xl font-semibold mb-2 mt-6">Sensitive Information</h3>
        <p>We do not intentionally collect sensitive personal information unless necessary with your explicit consent or as permitted by law.</p>
      </section>

      {/* 4. How We Process Your Information */}
      <section className="mb-12" id="how-we-process-your-information">
        <h2 className="text-2xl font-semibold mb-4">4. How We Process Your Information</h2>
        <p className="mb-4">We process your personal information for purposes such as:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>Providing and maintaining our Services</li>
          <li>Improving, personalizing, and expanding our Services</li>
          <li>Communicating with you regarding updates, offers, and promotions</li>
          <li>Ensuring security and preventing fraud</li>
          <li>Complying with legal obligations</li>
        </ul>
      </section>

      {/* 5. What Legal Bases Do We Rely On */}
      <section className="mb-12" id="legal-bases">
        <h2 className="text-2xl font-semibold mb-4">5. What Legal Bases Do We Rely On to Process Your Personal Information?</h2>
        <p className="mb-4">
          We only process your personal information when we have a valid legal reason to do so, such as:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>With your consent</li>
          <li>To fulfill our contractual obligations</li>
          <li>For our legitimate business interests</li>
          <li>To comply with legal requirements</li>
          <li>To protect vital interests</li>
        </ul>
      </section>

      {/* 6. When and With Whom Do We Share Your Personal Information */}
      <section className="mb-12" id="sharing-personal-information">
        <h2 className="text-2xl font-semibold mb-4">6. When and With Whom Do We Share Your Personal Information?</h2>
        <p className="mb-4">
          We may share your personal information with:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Service providers who assist in operating our Services</li>
          <li>Legal authorities when required by law</li>
          <li>Business partners with your consent</li>
          <li>In connection with business transfers (e.g., mergers, acquisitions)</li>
        </ul>
        <p>We do not sell your personal information to third parties.</p>
      </section>

      {/* 7. What Is Our Stance on Third-Party Websites */}
      <section className="mb-12" id="third-party-websites">
        <h2 className="text-2xl font-semibold mb-4">7. What Is Our Stance on Third-Party Websites?</h2>
        <p>
          Our Services may contain links to third-party websites or services. We are not responsible for the privacy practices of these external sites. We encourage you to review their privacy policies before providing any personal information.
        </p>
      </section>

      {/* 8. Is Your Information Transferred Internationally? */}
      <section className="mb-12" id="international-transfers">
        <h2 className="text-2xl font-semibold mb-4">8. Is Your Information Transferred Internationally?</h2>
        <p>
          Your information may be transferred to and processed in countries other than your own. We ensure that appropriate safeguards are in place to protect your data in accordance with this Privacy Policy and applicable laws.
        </p>
      </section>

      {/* 9. How Long Do We Keep Your Information? */}
      <section className="mb-12" id="data-retention">
        <h2 className="text-2xl font-semibold mb-4">9. How Long Do We Keep Your Information?</h2>
        <p>
          We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
        </p>
      </section>

      {/* 10. How Do We Keep Your Information Safe? */}
      <section className="mb-12" id="information-safety">
        <h2 className="text-2xl font-semibold mb-4">10. How Do We Keep Your Information Safe?</h2>
        <p>
          We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the Internet is 100% secure.
        </p>
      </section>

      {/* 11. Do We Collect Information from Minors? */}
      <section className="mb-12" id="information-from-minors">
        <h2 className="text-2xl font-semibold mb-4">11. Do We Collect Information from Minors?</h2>
        <p>
          Our Services are not intended for individuals under 18 years of age. We do not knowingly collect personal information from minors. If we become aware that we have collected information from a minor, we will take steps to delete it.
        </p>
      </section>

      {/* 12. What Are Your Privacy Rights? */}
      <section className="mb-12" id="privacy-rights">
        <h2 className="text-2xl font-semibold mb-4">12. What Are Your Privacy Rights?</h2>
        <p className="mb-4">
          Depending on your location, you may have certain rights regarding your personal information, including:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Accessing the personal information we hold about you</li>
          <li>Correcting or updating your personal information</li>
          <li>Deleting your personal information</li>
          <li>Objecting to or restricting the processing of your personal information</li>
          <li>Withdrawing your consent at any time</li>
        </ul>
        <p>
          To exercise these rights, please contact us at{' '}
          <a href="mailto:hello@novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
            hello@novacraftlab.com
          </a>
          .
        </p>
      </section>

      {/* 13. Controls for Do-Not-Track Features */}
      <section className="mb-12" id="do-not-track">
        <h2 className="text-2xl font-semibold mb-4">13. Controls for Do-Not-Track Features</h2>
        <p>
          Some web browsers and mobile operating systems offer a "Do Not Track" feature. Currently, no uniform technology standard for recognizing and implementing DNT signals has been adopted, so we do not respond to DNT signals at this time.
        </p>
      </section>

      {/* 14. Do United States Residents Have Specific Privacy Rights? */}
      <section className="mb-12" id="us-privacy-rights">
        <h2 className="text-2xl font-semibold mb-4">14. Do United States Residents Have Specific Privacy Rights?</h2>
        <p className="mb-4">
          Yes, residents of certain states like California, Colorado, Connecticut, Utah, and Virginia have specific privacy rights. These may include:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>The right to request disclosure of personal information collected</li>
          <li>The right to request deletion of personal information</li>
          <li>The right to opt-out of the sale of personal information</li>
          <li>The right to non-discrimination for exercising privacy rights</li>
        </ul>
        <p>
          To exercise these rights, please contact us at{' '}
          <a href="mailto:hello@novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
            hello@novacraftlab.com
          </a>
          .
        </p>
      </section>

      {/* 15. Do Other Regions Have Specific Privacy Rights? */}
      <section className="mb-12" id="other-regions-rights">
        <h2 className="text-2xl font-semibold mb-4">15. Do Other Regions Have Specific Privacy Rights?</h2>
        <p>
          Residents of other regions, such as the European Economic Area (EEA), United Kingdom (UK), Australia, New Zealand, and South Africa, may have additional rights regarding their personal information under applicable data protection laws.
        </p>
        <p>
          If you are a resident of these regions, please contact us to exercise your rights at{' '}
          <a href="mailto:hello@novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
            hello@novacraftlab.com
          </a>
          .
        </p>
      </section>

      {/* 16. Do We Make Updates to This Notice? */}
      <section className="mb-12" id="updates-to-notice">
        <h2 className="text-2xl font-semibold mb-4">16. Do We Make Updates to This Notice?</h2>
        <p>
          Yes, we may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new policy on this page.
        </p>
      </section>

      {/* 17. How Can You Contact Us About This Notice? */}
      <section className="mb-12" id="contact-us">
        <h2 className="text-2xl font-semibold mb-4">17. How Can You Contact Us About This Notice?</h2>
        <p>
          If you have any questions or comments about this Privacy Policy, please contact us at:
        </p>
        <p>
          <strong>Email:</strong>{' '}
          <a href="mailto:hello@novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
            hello@novacraftlab.com
          </a>
          <br />
          <strong>Address:</strong> 5830 E 2ND ST, STE 7000 #18865, CASPER, WY 82609
          <br />
          <strong>Website:</strong>{' '}
          <a href="https://novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
            novacraftlab.com
          </a>
        </p>
      </section>

      {/* 18. How Can You Review, Update, or Delete the Data We Collect from You? */}
      <section className="mb-12" id="review-update-delete">
        <h2 className="text-2xl font-semibold mb-4">18. How Can You Review, Update, or Delete the Data We Collect from You?</h2>
        <p>
          Based on the applicable laws of your country, you may have the right to request access to, correction of, or deletion of your personal information. To make such a request, please contact us at{' '}
          <a href="mailto:hello@novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
            hello@novacraftlab.com
          </a>
          .
        </p>
      </section>

      {/* Closing Statement */}
      <p className="text-center mt-12">
        Join us in shaping the future of iOS experiences, one app at a time.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
