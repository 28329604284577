// src/components/NewsletterSignup.js

import React from 'react';

function NewsletterSignup() {
  return (
    <section className="newsletter-section p-6 md:p-12 bg-white dark:bg-black">
      <div className="max-w-md mx-auto text-center text-gray-800 dark:text-white">
        <h2 className="text-3xl font-bold mb-4">
          Stay Updated on Our Latest iOS App Launches
        </h2>
        <p className="mb-8">
          Subscribe to our newsletter to get updates on our latest apps and features.
        </p>
        <form className="space-y-4">
          <div>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-800 dark:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Your Email Address"
              className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-800 dark:text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <button
              type="submit"
              className="w-full py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Subscribe Now
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default NewsletterSignup;
