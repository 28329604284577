// src/pages/AboutUs.js

import React from 'react';
import { motion } from 'framer-motion';

function AboutUs() {
  return (
    <motion.div
      className="text-gray-800 dark:text-white p-6 md:p-12 bg-white dark:bg-custom-gradient"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {/* Page Title */}
      <h1 className="text-4xl font-bold mb-8 text-center">About NOVA CRAFT LAB</h1>

      {/* Section: Our Story */}
      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Our Story</h2>
        <p className="mb-4">
          Founded with a vision to revolutionize the iOS app development landscape, <strong>NOVA CRAFT LAB LLC</strong> is where innovation meets execution. Our journey began when our CEO and founder, <strong>Mohcine Khalil</strong>, recognized a gap in the market for truly user-centric, high-quality iOS applications.
        </p>
        <p className="mb-4">
          In the heart of Casper, Wyoming, NOVA CRAFT LAB was born from a simple yet powerful idea: to craft iOS apps that not only meet but exceed user expectations. Mohcine, drawing from his extensive experience in tech and his passion for elegant, functional design, set out to build a team of like-minded innovators.
        </p>
        {/* Name Breakdown */}
        <div className="mb-4">
          <p className="mb-2">Our name, <strong>NOVA CRAFT LAB</strong>, reflects our essence:</p>
          <ul className="list-disc list-inside space-y-2">
            <li>
              <strong>NOVA:</strong> Signifying the bright, explosive energy of new ideas
            </li>
            <li>
              <strong>CRAFT:</strong> Emphasizing our commitment to quality and artisanship in coding
            </li>
            <li>
              <strong>LAB:</strong> Highlighting our experimental, innovative approach to problem-solving
            </li>
          </ul>
        </div>
        <p>
          From our modest beginnings, we've grown into a dynamic force in the iOS development world, known for our attention to detail and our ability to transform complex ideas into sleek, user-friendly applications.
        </p>
      </section>

      {/* Section: Our Approach */}
      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Our Approach</h2>
        <p className="mb-4">
          At NOVA CRAFT LAB, we believe that great apps are born from a deep understanding of user needs, cutting-edge technology, and meticulous craftsmanship. Our team of skilled developers, designers, and strategists work in concert to deliver iOS solutions that are not just functional, but delightful to use.
        </p>
        <p className="mb-4">We pride ourselves on:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>Collaborative partnerships with our clients</li>
          <li>Agile development methodologies</li>
          <li>Rigorous quality assurance processes</li>
          <li>A user-first design philosophy</li>
        </ul>
      </section>

      {/* Section: Our Promise */}
      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Our Promise</h2>
        <p className="mb-4">
          When you work with NOVA CRAFT LAB, you're not just getting an app; you're gaining a partner dedicated to your digital success. We're committed to clear communication, transparent processes, and results that speak for themselves.
        </p>
        <p>
          Ready to bring your iOS app idea to life? Let's craft something extraordinary together.
        </p>
      </section>

      {/* Section: Connect With Us */}
      <section className="mb-12">
        <h2 className="text-3xl font-semibold mb-4">Connect With Us</h2>
        <div className="space-y-2">
          <p>
            <strong>Email:</strong>{' '}
            <a href="mailto:hello@novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
              hello@novacraftlab.com
            </a>
          </p>
          <p>
            <strong>Address:</strong> 5830 E 2ND ST, STE 7000 #18865, CASPER, WY 82609
          </p>
          <p>
            <strong>Website:</strong>{' '}
            <a href="https://novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
              novacraftlab.com
            </a>
          </p>
        </div>
      </section>

      {/* Closing Statement */}
      <p className="text-center text-xl font-medium">
        Join us in shaping the future of iOS experiences, one app at a time.
      </p>
    </motion.div>
  );
}

export default AboutUs;
