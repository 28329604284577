// src/components/Header.js

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

function Header({ toggleTheme, currentTheme }) {
  const [isOpen, setIsOpen] = useState(false);

  // Logo paths
  const logoLight = '/nova-black.png'; // Path to the logo for light mode
  const logoDark = '/nova-white.png';  // Path to the logo for dark mode

  return (
    <header className="bg-white dark:bg-black text-gray-800 dark:text-white sticky top-0 z-50">
      <nav className="container mx-auto flex items-center justify-between p-4">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            src={currentTheme === 'dark' ? logoDark : logoLight}
            alt="NOVA CRAFT LAB LLC"
            className="h-10 w-auto"
          />
        </Link>
        {/* Desktop Menu */}
        <ul className="hidden md:flex space-x-6 items-center">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us">About</Link></li>
          <li><Link to="/contact-us">Contact Us</Link></li>
          {/* Theme Toggle Button */}
          <li className="ml-4">
            <button
              onClick={toggleTheme}
              className="focus:outline-none"
              aria-label="Toggle Dark Mode"
            >
              {currentTheme === 'dark' ? (
                <FontAwesomeIcon icon={faSun} className="text-white" size="lg" />
              ) : (
                <FontAwesomeIcon icon={faMoon} className="text-gray-800" size="lg" />
              )}
            </button>
          </li>
        </ul>
        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleTheme}
            className="mr-4 focus:outline-none"
            aria-label="Toggle Dark Mode"
          >
            {currentTheme === 'dark' ? (
              <FontAwesomeIcon icon={faSun} className="text-white" size="lg" />
            ) : (
              <FontAwesomeIcon icon={faMoon} className="text-gray-800" size="lg" />
            )}
          </button>
          <button onClick={() => setIsOpen(!isOpen)} aria-label="Toggle Menu">
            {/* Hamburger Icon */}
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </nav>
      {/* Mobile Menu */}
      {isOpen && (
        <motion.ul
          className="md:hidden bg-white dark:bg-gray-900 text-gray-800 dark:text-white"
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
        >
          <li className="p-2 border-b border-gray-300 dark:border-gray-700"><Link to="/">Home</Link></li>
          <li className="p-2 border-b border-gray-300 dark:border-gray-700"><Link to="/about-us">About</Link></li>
          <li className="p-2 border-b border-gray-300 dark:border-gray-700"><Link to="/contact-us">Contact Us</Link></li>
          {/* Theme Toggle Button in Mobile Menu */}
          <li className="p-2">
            <button
              onClick={toggleTheme}
              className="focus:outline-none"
              aria-label="Toggle Dark Mode"
            >
              {currentTheme === 'dark' ? (
                <FontAwesomeIcon icon={faSun} className="text-white" size="lg" />
              ) : (
                <FontAwesomeIcon icon={faMoon} className="text-gray-800" size="lg" />
              )}
            </button>
          </li>
        </motion.ul>
      )}
    </header>
  );
}

export default Header;
