// src/pages/ContactUs.js

import React from 'react';
import ContactForm from '../components/ContactForm';

function ContactUs() {
  return (
    <div className="text-gray-800 dark:text-white p-6 md:p-12 bg-white dark:bg-custom-gradient">
      {/* Page Title */}
      <h1 className="text-4xl font-bold mb-4 text-center">Contact Us</h1>

      {/* Introduction */}
      <p className="mb-8 text-center">
        We would love to hear from you! Whether you have a question about our services, need assistance, or just want to
        provide feedback, we're here to help.
      </p>

      {/* Contact Information */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
        <p className="mb-4">
          <strong>Email:</strong>{' '}
          <a href="mailto:hello@novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
            hello@novacraftlab.com
          </a>
        </p>
        <p className="mb-4">
          <strong>Address:</strong>
          <br />
          NOVA CRAFT LAB LLC
          <br />
          5830 E 2ND ST, STE 7000 #18865
          <br />
          CASPER, WY 82609
        </p>
      </section>

      {/* Contact Form */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Send Us a Message</h2>
        <ContactForm />
      </section>

      {/* Map Section */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Our Location</h2>
        {/* Replace the iframe src with your actual Google Maps embed link */}
        <div className="w-full h-64">
          <iframe
            title="Company Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3048.467795632484!2d-106.31308148460732!3d42.8482582791561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8760a2a40f5e6d4d%3A0x5b1c1a8c9e4c0a6e!2s5830%20E%202nd%20St%2C%20Casper%2C%20WY%2082609%2C%20USA!5e0!3m2!1sen!2sus!4v1634342337163!5m2!1sen!2sus"
            width="100%"
            height="100%"
            allowFullScreen=""
            loading="lazy"
            className="border-0"
          ></iframe>
        </div>
      </section>
    </div>
  );
}

export default ContactUs;
