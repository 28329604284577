// src/components/ContactForm.js

import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faEnvelope,
  faTag,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons';

function ContactForm() {
  const [formState, setFormState] = useState({
    name: '',
    _replyto: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormState({ 
      ...formState, 
      [e.target.name]: e.target.value 
    });
  };

  return (
    <form
      action="https://formspree.io/f/mdknqpdw"
      method="POST"
      className="space-y-6 max-w-md md:max-w-2xl mx-auto bg-white dark:bg-gray-900 p-8 rounded-lg shadow-lg"
    >
      <h2 className="text-2xl font-bold mb-6 text-center">Send Us a Message</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Name Field */}
        <div>
          <label className="block mb-2 text-gray-700 dark:text-gray-300">
            Name
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500 dark:text-gray-400">
              <FontAwesomeIcon icon={faUser} />
            </span>
            <input
              type="text"
              name="name"
              className="w-full pl-10 p-3 rounded-md bg-white dark:bg-gray-800 text-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onChange={handleChange}
              placeholder="Your Name"
              required
            />
          </div>
        </div>
        {/* Email Field */}
        <div>
          <label className="block mb-2 text-gray-700 dark:text-gray-300">
            Email
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500 dark:text-gray-400">
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <input
              type="email"
              name="_replyto"
              className="w-full pl-10 p-3 rounded-md bg-white dark:bg-gray-800 text-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onChange={handleChange}
              placeholder="Your Email"
              required
            />
          </div>
        </div>
        {/* Subject Field */}
        <div className="md:col-span-2">
          <label className="block mb-2 text-gray-700 dark:text-gray-300">
            Subject
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500 dark:text-gray-400">
              <FontAwesomeIcon icon={faTag} />
            </span>
            <input
              type="text"
              name="subject"
              className="w-full pl-10 p-3 rounded-md bg-white dark:bg-gray-800 text-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onChange={handleChange}
              placeholder="Subject"
              required
            />
          </div>
        </div>
        {/* Message Field */}
        <div className="md:col-span-2">
          <label className="block mb-2 text-gray-700 dark:text-gray-300">
            Message
          </label>
          <div className="relative">
            <span className="absolute top-0 left-0 mt-3 pl-3 flex items-center text-gray-500 dark:text-gray-400">
              <FontAwesomeIcon icon={faCommentDots} />
            </span>
            <textarea
              name="message"
              rows="5"
              className="w-full pl-10 p-3 rounded-md bg-white dark:bg-gray-800 text-gray-800 dark:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onChange={handleChange}
              placeholder="Your Message"
              required
            ></textarea>
          </div>
        </div>
      </div>
      <button
        type="submit"
        className="w-full py-3 mt-4"
      >
        Send Message
      </button>
    </form>
  );
}

export default ContactForm;
