import { Link } from 'react-router-dom';

function NotFound() {
  return (
    <div className="text-white text-center p-6">
      <h1 className="text-5xl font-bold mb-4">404</h1>
      <p className="mb-4">Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" className="px-6 py-2 bg-blue-600 hover:bg-blue-700 rounded-md">
        Go Back Home
      </Link>
    </div>
  );
}

export default NotFound;
