// src/pages/TermsOfUse.js

import React from 'react';

function TermsOfUse() {
  return (
    <div className="text-gray-800 dark:text-white p-6 md:p-12 bg-white dark:bg-custom-gradient">
      {/* Page Title */}
      <h1 className="text-4xl font-bold mb-8 text-center">Terms of Use</h1>

      {/* Table of Contents */}
      <section className="mb-12">
        <h2 className="text-2xl font-semibold mb-4">Table of Contents</h2>
        <ol className="list-decimal list-inside space-y-2">
          <li>
            <a href="#agreement" className="text-blue-600 dark:text-blue-400 underline">
              Agreement to Our Legal Terms
            </a>
          </li>
          <li>
            <a href="#services" className="text-blue-600 dark:text-blue-400 underline">
              Our Services
            </a>
          </li>
          <li>
            <a href="#intellectual-property" className="text-blue-600 dark:text-blue-400 underline">
              Intellectual Property Rights
            </a>
          </li>
          <li>
            <a href="#user-representations" className="text-blue-600 dark:text-blue-400 underline">
              User Representations
            </a>
          </li>
          <li>
            <a href="#purchases" className="text-blue-600 dark:text-blue-400 underline">
              Purchases and Payment
            </a>
          </li>
          <li>
            <a href="#free-trial" className="text-blue-600 dark:text-blue-400 underline">
              Free Trial
            </a>
          </li>
          <li>
            <a href="#cancellation" className="text-blue-600 dark:text-blue-400 underline">
              Cancellation
            </a>
          </li>
          <li>
            <a href="#software" className="text-blue-600 dark:text-blue-400 underline">
              Software
            </a>
          </li>
          <li>
            <a href="#prohibited-activities" className="text-blue-600 dark:text-blue-400 underline">
              Prohibited Activities
            </a>
          </li>
          <li>
            <a href="#user-contributions" className="text-blue-600 dark:text-blue-400 underline">
              User-Generated Contributions
            </a>
          </li>
          <li>
            <a href="#contribution-license" className="text-blue-600 dark:text-blue-400 underline">
              Contribution License
            </a>
          </li>
          <li>
            <a href="#reviews-guidelines" className="text-blue-600 dark:text-blue-400 underline">
              Guidelines for Reviews
            </a>
          </li>
          <li>
            <a href="#mobile-license" className="text-blue-600 dark:text-blue-400 underline">
              Mobile Application License
            </a>
          </li>
          <li>
            <a href="#third-party" className="text-blue-600 dark:text-blue-400 underline">
              Third-Party Websites and Content
            </a>
          </li>
          <li>
            <a href="#advertisers" className="text-blue-600 dark:text-blue-400 underline">
              Advertisers
            </a>
          </li>
          <li>
            <a href="#services-management" className="text-blue-600 dark:text-blue-400 underline">
              Services Management
            </a>
          </li>
          <li>
            <a href="#privacy-policy" className="text-blue-600 dark:text-blue-400 underline">
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="#termination" className="text-blue-600 dark:text-blue-400 underline">
              Term and Termination
            </a>
          </li>
          <li>
            <a href="#modifications" className="text-blue-600 dark:text-blue-400 underline">
              Modifications and Interruptions
            </a>
          </li>
          <li>
            <a href="#governing-law" className="text-blue-600 dark:text-blue-400 underline">
              Governing Law
            </a>
          </li>
          <li>
            <a href="#dispute-resolution" className="text-blue-600 dark:text-blue-400 underline">
              Dispute Resolution
            </a>
          </li>
          <li>
            <a href="#corrections" className="text-blue-600 dark:text-blue-400 underline">
              Corrections
            </a>
          </li>
          <li>
            <a href="#disclaimer" className="text-blue-600 dark:text-blue-400 underline">
              Disclaimer
            </a>
          </li>
          <li>
            <a href="#limitations" className="text-blue-600 dark:text-blue-400 underline">
              Limitations of Liability
            </a>
          </li>
          <li>
            <a href="#indemnification" className="text-blue-600 dark:text-blue-400 underline">
              Indemnification
            </a>
          </li>
          <li>
            <a href="#user-data" className="text-blue-600 dark:text-blue-400 underline">
              User Data
            </a>
          </li>
          <li>
            <a href="#electronic-communications" className="text-blue-600 dark:text-blue-400 underline">
              Electronic Communications, Transactions, and Signatures
            </a>
          </li>
          <li>
            <a href="#california-users" className="text-blue-600 dark:text-blue-400 underline">
              California Users and Residents
            </a>
          </li>
          <li>
            <a href="#miscellaneous" className="text-blue-600 dark:text-blue-400 underline">
              Miscellaneous
            </a>
          </li>
          <li>
            <a href="#contact-us" className="text-blue-600 dark:text-blue-400 underline">
              Contact Us
            </a>
          </li>
        </ol>
      </section>

      {/* Sections */}
      {/* 1. Agreement to Our Legal Terms */}
      <section className="mb-12" id="agreement">
        <h2 className="text-2xl font-semibold mb-4">1. Agreement to Our Legal Terms</h2>
        <p className="mb-4">
          Welcome to NOVA CRAFT LAB LLC ("Company," "we," "us," "our"). These Terms of Use ("Terms") constitute a
          legally binding agreement between you ("user," "you," or "your") and NOVA CRAFT LAB LLC concerning your access
          to and use of our services, including any content, functionality, and services offered on or through our iOS
          applications (the "Services").
        </p>
        <p className="mb-4">
          By accessing or using the Services, you acknowledge that you have read, understood, and agree to be bound by
          these Terms. If you do not agree with all of these Terms, you are expressly prohibited from using the Services
          and must discontinue use immediately.
        </p>
        <p>
          We reserve the right to make changes or modifications to these Terms at any time and for any reason. We will
          alert you about any changes by updating the "Last updated" date of these Terms. It is your responsibility to
          periodically review these Terms to stay informed of updates. Your continued use of the Services after the date
          such revised Terms are posted will constitute your acceptance of the changes.
        </p>
      </section>

      {/* 2. Our Services */}
      <section className="mb-12" id="services">
        <h2 className="text-2xl font-semibold mb-4">2. Our Services</h2>
        <p className="mb-4">
          The information provided when using the Services is not intended for distribution to or use by any person or
          entity in any jurisdiction where such distribution or use would be contrary to law or regulation or which
          would subject us to any registration requirement within such jurisdiction. You agree that you are accessing
          the Services on your own initiative and are responsible for compliance with local laws.
        </p>
        <p>
          The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not
          permitted to use or register for the Services.
        </p>
      </section>

      {/* 3. Intellectual Property Rights */}
      <section className="mb-12" id="intellectual-property">
        <h2 className="text-2xl font-semibold mb-4">3. Intellectual Property Rights</h2>
        <p className="mb-4">
          Unless otherwise indicated, the Services and all content, including but not limited to text, graphics, logos,
          images, and software (collectively, the "Content") are owned by us or our licensors and are protected by
          copyright and trademark laws.
        </p>
        <p>
          You are granted a limited license to access and use the Services and to download or print a copy of any
          portion of the Content to which you have properly gained access solely for your personal, non-commercial use.
          You shall not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform,
          republish, download, store, or transmit any of the Content without our prior written consent.
        </p>
      </section>

      {/* 4. User Representations */}
      <section className="mb-12" id="user-representations">
        <h2 className="text-2xl font-semibold mb-4">4. User Representations</h2>
        <p className="mb-4">By using the Services, you represent and warrant that:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>You have the legal capacity and agree to comply with these Terms.</li>
          <li>All registration information you submit will be true, accurate, current, and complete.</li>
          <li>
            You will maintain the accuracy of such information and promptly update such registration information as
            necessary.
          </li>
          <li>You will not access the Services through automated or non-human means.</li>
          <li>You will not use the Services for any illegal or unauthorized purpose.</li>
          <li>Your use of the Services will not violate any applicable law or regulation.</li>
        </ul>
      </section>

      {/* 5. Purchases and Payment */}
      <section className="mb-12" id="purchases">
        <h2 className="text-2xl font-semibold mb-4">5. Purchases and Payment</h2>
        <p className="mb-4">
          We may offer products and services for purchase ("In-App Purchases") through the App Store, Google Play, or
          other application platforms authorized by us. If you choose to make an In-App Purchase, you will be prompted
          to enter details for your account with your application platform ("IAP Account"), and your IAP Account will be
          charged for the In-App Purchase in accordance with the terms disclosed to you at the time of purchase as well
          as the general terms for In-App Purchases that apply to your IAP Account.
        </p>
      </section>

      {/* 6. Free Trial */}
      <section className="mb-12" id="free-trial">
        <h2 className="text-2xl font-semibold mb-4">6. Free Trial</h2>
        <p>
          We may offer a free trial of certain features or services for a limited period. At the end of the free trial
          period, you may be automatically enrolled in the corresponding subscription and charged the applicable
          subscription fee unless you cancel before the end of the trial period.
        </p>
      </section>

      {/* 7. Cancellation */}
      <section className="mb-12" id="cancellation">
        <h2 className="text-2xl font-semibold mb-4">7. Cancellation</h2>
        <p className="mb-4">
          You can cancel your subscription at any time through your account settings in the App Store or Google Play.
          Your cancellation will take effect at the end of the current paid term.
        </p>
        <p>
          If you are unsatisfied with our services, please contact us at{' '}
          <a href="mailto:hello@novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
            hello@novacraftlab.com
          </a>
          .
        </p>
      </section>

      {/* 8. Software */}
      <section className="mb-12" id="software">
        <h2 className="text-2xl font-semibold mb-4">8. Software</h2>
        <p>
          We grant you a non-exclusive, non-transferable, revocable license to access and use our software strictly in
          accordance with these Terms. You shall not modify, reverse-engineer, decompile, or disassemble the software.
        </p>
      </section>

      {/* 9. Prohibited Activities */}
      <section className="mb-12" id="prohibited-activities">
        <h2 className="text-2xl font-semibold mb-4">9. Prohibited Activities</h2>
        <p className="mb-4">You agree not to engage in any of the following prohibited activities:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>Copying or distributing any part of the Services without our prior written authorization.</li>
          <li>
            Using the Services in a manner inconsistent with any applicable laws or regulations, including those related
            to privacy and data protection.
          </li>
          <li>Uploading or transmitting viruses or other malicious code.</li>
          <li>
            Engaging in unauthorized framing of or linking to the Services without our express written permission.
          </li>
          <li>
            Harassing, annoying, intimidating, or threatening any of our employees or agents engaged in providing any
            portion of the Services to you.
          </li>
        </ul>
      </section>

      {/* 10. User-Generated Contributions */}
      <section className="mb-12" id="user-contributions">
        <h2 className="text-2xl font-semibold mb-4">10. User-Generated Contributions</h2>
        <p className="mb-4">
          The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums,
          and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit,
          perform, publish, distribute, or broadcast content and materials to us or on the Services.
        </p>
        <p>
          Any contributions you transmit to us will be treated as non-confidential and non-proprietary. By providing any
          User Contributions, you grant us a license to use and distribute such content as we see fit.
        </p>
      </section>

      {/* 11. Contribution License */}
      <section className="mb-12" id="contribution-license">
        <h2 className="text-2xl font-semibold mb-4">11. Contribution License</h2>
        <p>
          By posting your Contributions to any part of the Services, you automatically grant us an unrestricted,
          unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right and
          license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, and distribute such
          Contributions for any purpose.
        </p>
      </section>

      {/* 12. Guidelines for Reviews */}
      <section className="mb-12" id="reviews-guidelines">
        <h2 className="text-2xl font-semibold mb-4">12. Guidelines for Reviews</h2>
        <p className="mb-4">When posting a review, you must comply with the following criteria:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>You should have firsthand experience with the entity being reviewed.</li>
          <li>Your reviews should not contain offensive profanity or abusive language.</li>
          <li>Your reviews should not contain discriminatory references.</li>
          <li>Your reviews should not contain references to illegal activity.</li>
        </ul>
      </section>

      {/* 13. Mobile Application License */}
      <section className="mb-12" id="mobile-license">
        <h2 className="text-2xl font-semibold mb-4">13. Mobile Application License</h2>
        <p className="mb-4">Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>Download and install the mobile application on a device that you own or control.</li>
          <li>Access and use the mobile application on such device strictly in accordance with these Terms.</li>
        </ul>
      </section>

      {/* 14. Third-Party Websites and Content */}
      <section className="mb-12" id="third-party">
        <h2 className="text-2xl font-semibold mb-4">14. Third-Party Websites and Content</h2>
        <p>
          The Services may contain links to other websites ("Third-Party Websites") as well as articles, photographs,
          text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content
          or items belonging to or originating from third parties ("Third-Party Content"). We do not control or monitor
          such Third-Party Websites or Third-Party Content and are not responsible for them.
        </p>
      </section>

      {/* 15. Advertisers */}
      <section className="mb-12" id="advertisers">
        <h2 className="text-2xl font-semibold mb-4">15. Advertisers</h2>
        <p>
          We allow advertisers to display their advertisements and other information in certain areas of the Services,
          such as sidebar advertisements or banner advertisements. We simply provide the space to place such
          advertisements, and we have no other relationship with advertisers.
        </p>
      </section>

      {/* 16. Services Management */}
      <section className="mb-12" id="services-management">
        <h2 className="text-2xl font-semibold mb-4">16. Services Management</h2>
        <p>
          We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Terms; (2)
          take appropriate legal action against anyone who, in our sole discretion, violates the law or these Terms; (3)
          in our sole discretion and without limitation, refuse, restrict access to, or disable your access to the
          Services.
        </p>
      </section>

      {/* 17. Privacy Policy */}
      <section className="mb-12" id="privacy-policy">
        <h2 className="text-2xl font-semibold mb-4">17. Privacy Policy</h2>
        <p>
          We care about data privacy and security. Please review our{' '}
          <a href="/privacy-policy" className="text-blue-600 dark:text-blue-400 underline">
            Privacy Policy
          </a>
          . By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Terms.
        </p>
      </section>

      {/* 18. Term and Termination */}
      <section className="mb-12" id="termination">
        <h2 className="text-2xl font-semibold mb-4">18. Term and Termination</h2>
        <p>
          These Terms shall remain in full force and effect while you use the Services. We may terminate or suspend your
          access to the Services at any time, without prior notice or liability, for any reason whatsoever.
        </p>
      </section>

      {/* 19. Modifications and Interruptions */}
      <section className="mb-12" id="modifications">
        <h2 className="text-2xl font-semibold mb-4">19. Modifications and Interruptions</h2>
        <p>
          We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason
          at our sole discretion without notice. We also reserve the right to modify or discontinue all or part of the
          Services without notice at any time.
        </p>
      </section>

      {/* 20. Governing Law */}
      <section className="mb-12" id="governing-law">
        <h2 className="text-2xl font-semibold mb-4">20. Governing Law</h2>
        <p>
          These Terms and your use of the Services are governed by and construed in accordance with the laws of the
          State of Wyoming, without regard to its conflict of law principles.
        </p>
      </section>

      {/* 21. Dispute Resolution */}
      <section className="mb-12" id="dispute-resolution">
        <h2 className="text-2xl font-semibold mb-4">21. Dispute Resolution</h2>
        <p>
          Any legal action of whatever nature brought by either you or us shall be commenced or prosecuted in the state
          and federal courts located in Casper, Wyoming, and the parties hereby consent to, and waive all defenses of
          lack of personal jurisdiction and forum non conveniens with respect to venue and jurisdiction in such state
          and federal courts.
        </p>
      </section>

      {/* 22. Corrections */}
      <section className="mb-12" id="corrections">
        <h2 className="text-2xl font-semibold mb-4">22. Corrections</h2>
        <p>
          There may be information on the Services that contains typographical errors, inaccuracies, or omissions,
          including descriptions, pricing, availability, and various other information. We reserve the right to correct
          any errors, inaccuracies, or omissions and to change or update the information on the Services at any time,
          without prior notice.
        </p>
      </section>

      {/* 23. Disclaimer */}
      <section className="mb-12" id="disclaimer">
        <h2 className="text-2xl font-semibold mb-4">23. Disclaimer</h2>
        <p>
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE
          AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
          CONNECTION WITH THE SERVICES AND YOUR USE THEREOF.
        </p>
      </section>

      {/* 24. Limitations of Liability */}
      <section className="mb-12" id="limitations">
        <h2 className="text-2xl font-semibold mb-4">24. Limitations of Liability</h2>
        <p>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
          INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST
          REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES.
        </p>
      </section>

      {/* 25. Indemnification */}
      <section className="mb-12" id="indemnification">
        <h2 className="text-2xl font-semibold mb-4">25. Indemnification</h2>
        <p>
          You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our
          respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or
          demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of:
          (1) use of the Services; (2) breach of these Terms; (3) any breach of your representations and warranties set
          forth in these Terms.
        </p>
      </section>

      {/* 26. User Data */}
      <section className="mb-12" id="user-data">
        <h2 className="text-2xl font-semibold mb-4">26. User Data</h2>
        <p>
          We will maintain certain data that you transmit to the Services for the purpose of managing the performance of
          the Services, as well as data relating to your use of the Services. You are solely responsible for all data
          that you transmit or that relates to any activity you have undertaken using the Services.
        </p>
      </section>

      {/* 27. Electronic Communications, Transactions, and Signatures */}
      <section className="mb-12" id="electronic-communications">
        <h2 className="text-2xl font-semibold mb-4">27. Electronic Communications, Transactions, and Signatures</h2>
        <p>
          Visiting the Services, sending us emails, and completing online forms constitute electronic communications.
          You consent to receive electronic communications, and you agree that all agreements, notices, disclosures, and
          other communications we provide to you electronically, via email and on the Services, satisfy any legal
          requirement that such communication be in writing.
        </p>
      </section>

      {/* 28. California Users and Residents */}
      <section className="mb-12" id="california-users">
        <h2 className="text-2xl font-semibold mb-4">28. California Users and Residents</h2>
        <p>
          If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of the
          Division of Consumer Services of the California Department of Consumer Affairs.
        </p>
      </section>

      {/* 29. Miscellaneous */}
      <section className="mb-12" id="miscellaneous">
        <h2 className="text-2xl font-semibold mb-4">29. Miscellaneous</h2>
        <p className="mb-4">
          These Terms and any policies or operating rules posted by us on the Services constitute the entire agreement
          and understanding between you and us. Our failure to exercise or enforce any right or provision of these Terms
          shall not operate as a waiver of such right or provision.
        </p>
        <p>
          These Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and
          obligations to others at any time.
        </p>
      </section>

      {/* 30. Contact Us */}
      <section className="mb-12" id="contact-us">
        <h2 className="text-2xl font-semibold mb-4">30. Contact Us</h2>
        <p>
          If you have any questions about these Terms, please contact us at:{' '}
          <a href="mailto:hello@novacraftlab.com" className="text-blue-600 dark:text-blue-400 underline">
            hello@novacraftlab.com
          </a>
          .
        </p>
      </section>

      {/* Closing Statement */}
      <p className="text-center mt-12">
        By using our Services, you agree to these Terms of Use. Thank you for choosing NOVA CRAFT LAB LLC.
      </p>
    </div>
  );
}

export default TermsOfUse;
