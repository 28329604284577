// src/components/Footer.js

import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer className="bg-white dark:bg-black text-gray-800 dark:text-white mt-8">
      <div className="container mx-auto p-4 flex flex-col md:flex-row items-center justify-between">
        {/* Company Information */}
        <p className="mb-4 md:mb-0 text-center md:text-left">
          © {new Date().getFullYear()} NOVA CRAFT LAB LLC. All rights reserved.
        </p>

        {/* Navigation Links */}
        <ul className="flex flex-wrap justify-center md:justify-end space-x-4">
          <li>
            <Link to="/about-us" className="hover:underline">
              About
            </Link>
          </li>
          <li>
            <Link to="/terms-of-use" className="hover:underline">
              Terms of Use
            </Link>
          </li>
          <li>
            <Link to="/privacy-policy" className="hover:underline">
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link to="/contact-us" className="hover:underline">
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
