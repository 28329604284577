// src/pages/Home.js

import React from 'react';
import { motion } from 'framer-motion';
import AnimatedGraphic from '../components/AnimatedGraphic';
import NewsletterSignup from '../components/NewsletterSignup';

// Import FontAwesome Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faCheckCircle, faUserAlt } from '@fortawesome/free-solid-svg-icons';

function Home() {
  return (
    <div className="text-gray-800 dark:text-white">
      {/* Hero Section */}
      <section className="text-center p-12 bg-white dark:bg-black">
        <motion.h1
          className="text-5xl font-bold mb-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          Imagine. Create. Innovate.
        </motion.h1>
        <p className="mb-8 text-lg">
        Transforming Ideas into Cutting-Edge Mobile Experiences with Innovative Design and Powerful Functionality.
        </p>

        {/* Apps Coming Soon Message */}
        <motion.div
          className="mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <p className="text-xl font-semibold">Our apps are coming soon!</p>
          <p>Subscribe Now for VIP Updates and Early Access.</p>
        </motion.div>

        {/* Animated Graphic */}
        <AnimatedGraphic />

        {/* Call to Action Button */}
        <a href="/contact-us" className="btn inline-block mt-8">
          Contact Us
        </a>
      </section>

      {/* Features Section */}
      <section className="p-6 bg-purple-gradient">
        <h2 className="text-3xl font-bold mb-4 text-center">Our Core Values</h2>
        <div className="grid md:grid-cols-3 gap-6">
          {/* Innovation */}
          <div className="bg-white bg-opacity-70 dark:bg-black dark:bg-opacity-70 p-4 rounded-md">
            <div className="flex items-center mb-2">
              <FontAwesomeIcon
                icon={faLightbulb}
                size="lg"
                className="text-black dark:text-white mr-2"
              />
              <h3 className="text-xl font-semibold">Innovation</h3>
            </div>
            <p>
              We constantly challenge ourselves to break new ground, leveraging cutting-edge technologies and creative problem-solving to craft iOS apps that stand out in the digital landscape.
            </p>
          </div>

          {/* Quality */}
          <div className="bg-white bg-opacity-70 dark:bg-black dark:bg-opacity-70 p-4 rounded-md">
            <div className="flex items-center mb-2">
              <FontAwesomeIcon
                icon={faCheckCircle}
                size="lg"
                className="text-black dark:text-white mr-2"
              />
              <h3 className="text-xl font-semibold">Quality</h3>
            </div>
            <p>
            We meticulously design and rigorously test each iOS app, ensuring robust performance, seamless functionality, and a polished user experience that sets new standards in the App Store.
            </p>
          </div>

          {/* User-Centric Design */}
          <div className="bg-white bg-opacity-70 dark:bg-black dark:bg-opacity-70 p-4 rounded-md">
            <div className="flex items-center mb-2">
              <FontAwesomeIcon
                icon={faUserAlt}
                size="lg"
                className="text-black dark:text-white mr-2"
              />
              <h3 className="text-xl font-semibold">User-Centric Design</h3>
            </div>
            <p>
            We craft intuitive interfaces and seamless interactions, putting users at the heart of our design process. Every tap, swipe, and feature is thoughtfully created to delight and empower our clients' audiences.
            </p>
          </div>
        </div>
      </section>

      {/* Newsletter Signup Section */}
      <NewsletterSignup />
    </div>
  );
}

export default Home;
