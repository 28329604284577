// src/components/AnimatedGraphic.js

import React from 'react';
import './AnimatedGraphic.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';

function AnimatedGraphic() {
  return (
    <div className="animation-container">
      <div className="center-icon text-black dark:text-white">
        <FontAwesomeIcon icon={faApple} size="3x" />
      </div>
      <div className="circle circle1"></div>
      <div className="circle circle2"></div>
      <div className="circle circle3"></div>
    </div>
  );
}

export default AnimatedGraphic;
